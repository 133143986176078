import { PrismicNextImage } from '@prismicio/next';
import classNames from 'classnames';
import MissingRequiredFields from 'components/MissingRequiredFields';
import PrismicRichText from 'components/PrismicRichText';
import SectionHeader from 'components/SectionHeaderSlice';
import React from 'react';
import { TextWithImageSlice } from 'types.generated';
import { getResponsiveImgHeight } from 'util/getResponsiveImgSize';

import styles from './TextWithImage.module.scss';

export const TextWithImage = ({ slice }: { slice: TextWithImageSlice }) => {
  const {
    title,
    desktop_alignment: desktopAlignment,
    mobile_alignment: mobileAlignment,
    underline,
    body,
    image,
    image_position: imagePosition,
    badge,
    is_column_width_flexible: isColumnWidthFlexible,
  } = slice.primary;

  if (!title.length || !body.length || !image) return <MissingRequiredFields />;

  /* The repeatable zone array (slice.items), when left empty, will still include one item.
  We render the section only if the first item of the repeatable zone has been filled out with any content. */
  const firstRepeatableItem = slice.items[0];

  const isRepeatableZoneFilled =
    firstRepeatableItem &&
    (firstRepeatableItem.section_title ||
      firstRepeatableItem.section_description ||
      firstRepeatableItem.section_description_richtext.length ||
      Object.keys(firstRepeatableItem.section_icon).length);

  /* We calculate the image height based on the max width we know the image will have on the page, and pass the dimensions to PrismicNextImage:
  https://nextjs.org/docs/pages/building-your-application/optimizing/images#image-sizing */
  const RESIZED_IMAGE_WIDTH = 400;
  const responsiveHeight = getResponsiveImgHeight(
    RESIZED_IMAGE_WIDTH,
    image.dimensions
  );

  return (
    <section
      className={classNames(
        'p-body d-flex ai-center gap32 mt56',
        styles.container,
        { [styles.fixedColumn]: !isColumnWidthFlexible },
        { 'text-img-container': !isRepeatableZoneFilled }
      )}
    >
      <div
        className={classNames(styles.imageContainer, {
          [styles.right]: imagePosition === 'RIGHT',
          [styles.left]: imagePosition !== 'RIGHT',
        })}
      >
        <PrismicNextImage
          field={image}
          width={RESIZED_IMAGE_WIDTH}
          height={responsiveHeight}
        />
      </div>
      <div className={imagePosition === 'RIGHT' ? styles.left : styles.right}>
        {badge && (
          <div
            className={`fw-bold p-badge p-badge--default mb8 ${styles.badge}`}
          >
            {badge}
          </div>
        )}
        <SectionHeader
          title={title}
          desktopAlignment={desktopAlignment}
          mobileAlignment={mobileAlignment}
          underline={underline}
        />
        <div className={`mt16 ${styles.paragraphWrapper}`}>
          <PrismicRichText richText={body} />
        </div>
        {Boolean(isRepeatableZoneFilled) && (
          <div
            className={classNames(
              'd-flex fd-column gap32',
              styles.itemContainer
            )}
          >
            {slice.items.map((item) => (
              <div className="d-flex gap16" key={item.section_title}>
                <div>
                  <PrismicNextImage
                    field={item.section_icon}
                    width={32}
                    height={32}
                  />
                </div>
                <div>
                  <h3 className="p-h4">{item.section_title}</h3>
                  {item.section_description_richtext?.length ? (
                    <div className="wmx7 mt8">
                      <PrismicRichText
                        richText={item.section_description_richtext}
                        paragraphClassName="p-p tc-grey-600"
                      />
                    </div>
                  ) : (
                    <p className="p-p tc-grey-600 wmx7 mt8">
                      {item.section_description}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
